<template>
  <div class="search">
    <label :for="`site-search-${ariaId}`" class="sr-only"
      >Search Wine Access</label
    >
    <input
      :id="`site-search-${ariaId}`"
      ref="searchInputBox"
      v-model="query"
      type="search"
      placeholder="Search"
      :class="{ dirty: query !== '' }"
      @blur="setSearchFocused(false)"
      @focus="setSearchFocused(true)"
      @keyup.enter="goToSearch()"
    />
    <button v-if="!query">
      <span class="sr-only">Search</span>
    </button>
    <button v-else class="clear" @click="hideSearch()">
      <span class="sr-only">Clear Search</span>
    </button>
    <button v-if="mobileSearchOpen" class="close" @click="closeMobileSearch()">
      Close
    </button>
    <div v-if="(searchHasFocus || mobileSearchOpen)">
      <MegaSearch :hide-search="hideSearch" :query="query" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { pushEvent } from '~/../common/utils/user'
import { getHeaderHeight, debounce } from '~/../common/components/Header/utils'
import ga4 from '~/../common/utils/ga4'
// TODO: Wire up Account based on auth; notifications; cart items
export default {
  name: 'HeaderSearch',
  components: {
    MegaSearch: () => import('~/components/Header/MegaSearch.vue'),
  },
  props: {
    ariaId: {
      type: String,
      required: false,
      default: 'default',
    },
  },
  data() {
    return {
      searchHasFocus: false,
      query: '',
      searchResultsPosition: 130,
    }
  },
  computed: {
    searchOpen: {
      get() {
        return this.$store.getters.searchOpen
      },
      set(value) {
        this.$store.dispatch('setSearchOpen', value)
      },
    },
    ...mapGetters([
      'count',
      'ltoCount',
      'results',
      'searchExecuting',
      'anySearchExecuting',
      'mobileSearchOpen',
      'homepageSearchExecuting',
      'searchCollections',
      'isSingleCollectionResults',
      'getStoreSearchUrl',
    ]),
  },
  watch: {
    query(newValue, oldValue) {
      this.executeSearch(newValue)
    },
    showPromoBanner(newValue, oldValue) {
      this.adjustSearchResultsPosition()
    },
    searchHasFocus(newValue) {
      if (newValue) {
        this.openSearch()
      }
    },
    anySearchExecuting(newValue, oldValue) {
      if (!newValue && oldValue) {
        if (this.query.length > 2) {
          ga4.track('search', {
            search_location: this.homepageSearchExecuting ? 'homepage' : 'nav',
            query: this.query,
            store_count: this.count,
            lto_count: this.ltoCount,
          })
          pushEvent(
            this.$axios,
            {
              metric_name: 'Global Navigation Search Executed',
              url_path: this.$route.fullPath,
              extra_data: {
                search_location: this.homepageSearchExecuting
                  ? 'homepage'
                  : 'nav',
                search_string: this.query,
                store_count: this.count,
                lto_count: this.ltoCount,
              },
            },
            this.$store
          )
        }
      }
    },
  },
  created() {
    this.executeSearch = debounce(this.executeSearch, 500)
    this.$root.$on('focusSearchBox', this.openSearch)
  },
  mounted() {
    this.adjustSearchResultsPosition()
    // Listen for resize events
    window.addEventListener('resize', this.adjustSearchResultsPosition)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.adjustSearchResultsPosition)
    this.$root.$off('focusSearchBox', this.openSearch)
  },
  methods: {
    executeSearch(query) {
      this.$store.dispatch('executeSearch', query)
      if (!query.length) {
        this.$store.dispatch('ltoExecuteEmptySearch')
      } else {
        this.$store.dispatch('ltoExecuteSearch', query)
      }
    },
    adjustSearchResultsPosition() {
      if (process.client) {
        this.$nextTick(() => {
          const height = getHeaderHeight()
          this.searchResultsPosition = height + 'px'
        })
      }
    },
    openSearch() {
      this.searchOpen = true
      this.adjustSearchResultsPosition()
      this.$nextTick(() => {
        this.$refs.searchInputBox.focus()
      })
    },
    closeSearch() {
      this.hideSearch()
      this.searchOpen = false
      if (this.$route.params.category === 'search') {
        this.$router.push('/store/') // todo: ideally we keep any filters that were added
      }
    },
    closeMobileSearch() {
      this.$store.dispatch('setMobileSearchOpen', false)
    },
    hideSearch() {
      this.closeMobileSearch()
      this.$emit('closed')
      this.query = ''
      this.$store.dispatch('clearSearch')
    },
    setSearchFocused(v) {
      const delay = v ? 100 : 500
      setTimeout(() => {
        this.searchHasFocus = v
      }, delay)
    },
    goToSearch() {
      if (this.executeSearch.isPending() || this.anySearchExecuting) {
        this.$refs.searchInputBox.blur()
        this.$router.push(this.getStoreSearchUrl(this.query, null, false))
        this.hideSearch()
      }
      const storeSearchUrl = this.getStoreSearchUrl(this.query)
      if (storeSearchUrl === '/store/') {
        return
      }
      this.$refs.searchInputBox.blur()
      this.$router.push(storeSearchUrl)
      this.hideSearch()
    },
  },
}
</script>
